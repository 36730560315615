// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-posts-page-layout-tsx": () => import("./../../../src/components/posts-page-layout.tsx" /* webpackChunkName: "component---src-components-posts-page-layout-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-form-tsx": () => import("./../../../src/pages/contact-us/form.tsx" /* webpackChunkName: "component---src-pages-contact-us-form-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-i-am-a-patient-form-tsx": () => import("./../../../src/pages/i-am-a-patient/form.tsx" /* webpackChunkName: "component---src-pages-i-am-a-patient-form-tsx" */),
  "component---src-pages-i-am-a-patient-index-tsx": () => import("./../../../src/pages/i-am-a-patient/index.tsx" /* webpackChunkName: "component---src-pages-i-am-a-patient-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-register-form-tsx": () => import("./../../../src/pages/register/form.tsx" /* webpackChunkName: "component---src-pages-register-form-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

